.option {
    background-color: #262626 !important;
}

.option ::selection {
    background-color: #262626 !important;
}

.option-topic {
    background-color: #4541BB !important;
}